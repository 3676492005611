import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);

function getPublic(checkoutId) {
  return storeRequest.get(`/orders/public/abcheckouts/${checkoutId}`);
}
function getGenerator(param) {
  return storeRequest.get('/orders/abcheckouts/generator/', param);
}
function getCount(param) {
  return storeRequest.get('/orders/abcheckouts/count/', param);
}
// list id ở ngoài
function getAbcheckouts(param) {
  return storeRequest.get('/orders/abcheckouts/', {
    params: param,
  });
}
// từng id
function getListId(checkoutId) {
  return storeRequest.get(`/orders/abcheckouts/${checkoutId}`);
}
function getTimeline(checkoutId) {
  return storeRequest.get(`/orders/abcheckouts/${checkoutId}/timeline`);
}
export const abcheckoutApi = {
  getPublic,
  getGenerator,
  getCount,
  getAbcheckouts,
  getListId,
  getTimeline,
};
