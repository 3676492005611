<template>
  <div class="order-page--detail">
    <v-row no-gutters>
      <v-col cols="12">
        <redirect class="mb-5" title="Abandoned Checkouts" route="website.abandoned" />
        <div class="page-header" v-if="!loading">
          <h2 class="">{{ data.name }}</h2>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutter v-if="!loading">
      <!-- ###################################################### AUTO MATE -->
      <v-col cols="12" class="">
        <div v-if="!autoSendAbandonedEmail" class="automate d-flex px-4 py-3 mb-4">
          <v-icon class="mr-4" color="primary">mdi-information-outline</v-icon>
          <div>
            <div class="mb-2">
              <h3>Automate abandoned checkout notifications</h3>
            </div>
            <div class="mb-2">
              Set up to send automatic emails/text messages to customers who left products in their cart but didn’t
              complete their order. These emails can help recover 5–20% of lost sales.
            </div>
            <v-btn @click="$router.push({ name: 'website.settings.checkout' })" outlined
              >Set up automatic abandoned checkout notifications</v-btn
            >
          </div>
        </div>
      </v-col>
      <!--###################################################### Left -->
      <v-col v-if="data" cols="12" sm="8" class="d-flex flex-column">
        <!--###################################################### TIMELINE -->
        <div class="elevation-form mb-4">
          <div class="pa-4">
            <span class="title-info-customer"> Timeline </span>
          </div>
          <v-divider class="mb-4"></v-divider>
          <v-timeline class="timeline" align-top dense>
            <v-timeline-item color="#a98a57" small>
              <v-row class="">
                <v-col class="timeline-link pt-0">Abandoned checkout at {{ formatDate(data.created_at) }}</v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
        </div>
        <!--###################################################### CHECKOUT DETAIL - SUBTOTAL -->
        <div v-if="data.fulfillmentStatus !== 'fulfilled'" class="elevation-form">
          <div class="d-flex align-center mb-6 pa-4">
            <h4>
              <!-- {{ data.fulfillmentStatus }} -->
              Checkout details
            </h4>
          </div>
          <v-row v-for="(item, index) in items" :key="index" class="pa-4">
            <v-col v-if="index != 0" sm="12">
              <v-divider></v-divider>
            </v-col>
            <v-col class="text-center" sm="2">
              <v-avatar>
                <img :src="imageHelpers.url(item.image, { width: 50, height: 50 })" />
              </v-avatar>
            </v-col>
            <v-col sm="6">
              <div class="primary--text">
                {{ utilities.ucfirst(item.handle) }}
              </div>
              <div>Name: {{ item.title }}</div>
              <div>SKU: {{ item.sku }}</div>
            </v-col>
            <v-col sm="2" class="px-0 text-center">
              {{ currency.priceFormat(parseFloat(item.price).toFixed(2)) }} x {{ item.qty }}
            </v-col>
            <v-col sm="2" class="text-right">{{ currency.priceFormat(parseFloat(item.row_total).toFixed(2)) }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <div class="pa-4">
            <v-row class="justify-space-between">
              <v-col>Subtotal</v-col>
              <v-col class="text-right">{{ currency.priceFormat(parseFloat(prices.sub_total).toFixed(2)) }}</v-col>
            </v-row>
            <v-row class="justify-space-between">
              <v-col>Shipping</v-col>
              <v-col class="text-right">{{ currency.priceFormat(parseFloat(prices.shipping_total).toFixed(2)) }}</v-col>
            </v-row>
            <v-row class="justify-space-between" v-if="parseFloat(prices.discount_amount)">
              <v-col>Discount</v-col>
              <v-col class="text-right">
                -{{ currency.priceFormat(parseFloat(prices.discount_amount).toFixed(2)) }}
              </v-col>
            </v-row>
            <v-row class="justify-space-between">
              <v-col><strong>Total</strong></v-col>
              <v-col class="text-right"
                ><strong>{{ currency.priceFormat(parseFloat(prices.grand_total || 0).toFixed(2)) }}</strong></v-col
              >
            </v-row>
          </div>
        </div>
        <div class="pa-4 elevation-form">
          <h4>Additional details</h4>
          <div class="grey--text">Note</div>
          <v-combobox deletable-chips chips placeholder="Add a note to this checkout" close></v-combobox>
          <!-- <div class="d-flex justify-end">
            <v-btn class="white--text" color="#a98a57">Save</v-btn>
          </div> -->
        </div>
      </v-col>
      <!-- #################################################### right -->
      <v-col sm="4">
        <div class="elevation-form">
          <div class="pa-4">
            <h4>Customer</h4>
            <v-row class="align-center">
              <v-col sm="3" class="mt-2">
                <v-avatar>
                  <!-- <v-img :src="data.cart.paidInfo.items[0].image"></v-img> -->
                  <v-img :src="imageFake.user"></v-img>
                </v-avatar>
              </v-col>
              <v-col sm="8">
                <div class="username mt-2 customer-name">{{ data.customer.full_name }}</div>
                <div class="customer-name">{{ data.cart.paidInfo.items_qty }} order</div>
              </v-col>
            </v-row>
            <div class="user-email mt-2">{{ data.customer.email }}</div>
            <div>{{ data.customer.phone }}</div>
            <!-- <div>No Account</div> -->
          </div>
          <v-divider></v-divider>
          <div class="pa-4">
            <h4>SHIPPING ADDRESS</h4>
            <div>{{ data.customer.address1 }}</div>
            <div>{{ data.customer.address2 }}</div>
            <div>{{ data.customer.city }}</div>
            <div>{{ data.customer.country_code }}</div>
            <div>{{ data.customer.province }}</div>
            <div>{{ data.customer.country }}</div>
            <div>{{ data.customer.zip }}</div>
          </div>
          <v-divider></v-divider>
          <div class="pa-4">
            <h4>BILLING ADDRESS</h4>
            <div>Same as shipping address</div>
          </div>
          <v-divider></v-divider>
          <div class="pa-4">
            <h4>PHONE NUMBER</h4>
            <div>{{ data.customer.phone }}</div>
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- <div style="width: 100%; height: 100%" v-if="loading" class="d-flex justify-center align-center">
      <page-loader />
    </div> -->
    <delete-action :content="'product ' + name" :title="'Delete ' + name" />
    <BeforeLeavePage />
  </div>
</template>

<script>
import utilities from '@/helpers/utilities';
import { required, minLength, maxLength, between, email } from 'vuelidate/lib/validators';
import { abcheckoutApi } from '@/apis/abandons';
import { settingsApi } from '@/apis/settings';
import { imageFake } from '@/const/image';
import imageHelpers from '@/helpers/image';
import STORAGE_NAME from '@/const/storage';
import datetime from '@/helpers/datetime';
import moment from 'moment';
import 'moment-timezone';
import { log } from 'handlebars';
import { FETCH_PAGE_PREFERRENCE } from '@/store/actions.type';
import currency from '@/helpers/currency';
export default {
  // mixins: [resourceBeforeLeavePage],
  components: {},
  data() {
    return {
      utilities,
      prices: '',
      timeline: [],
      datetime,
      events: [],
      input: null,
      nonce: 0,
      imageFake,
      imagesSelect: [],
      isVariantDefault: false,
      showDialogDelete: false,
      name: '',
      idItem: null,
      isLoading: false,
      loading: true,
      page: {},
      showHeader: false,
      searchString: 'null hihi',
      listVariants: [],
      // Product options
      options: [], // need
      imageHelpers,
      data: null,
      totalPrice: 0,
      fulFillmentList: [],
      autoSendAbandonedEmail: null,
      currency,
    };
  },
  async created() {
    await this.$store.dispatch(FETCH_PAGE_PREFERRENCE);
    this.getData();
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        this.idItem = this.$route.params.id;
        let storeDomain = localStorage.getItem(STORAGE_NAME.STORE_DOMAIN);
        this.data = (await abcheckoutApi.getListId(this.idItem)).data;
        this.items = this.data.cart.paidInfo.items;
        this.prices = this.data.cart.paidInfo;
        this.data.name = `#${this.data.cart.id.slice(0, 9)}`;
        this.timeline = (await abcheckoutApi.getTimeline(this.idItem)).data[0];
        // lấy setting của store xem có display auto aband không
        let res = await settingsApi.getCheckout();
        console.log(res);
        this.autoSendAbandonedEmail = res.data.abandonedCheckout.automaticSentAbandonedEmail;
        this.loading = false;
        console.log(this.data);
      } catch (error) {
        this.loading = false;
        // console.log(error, 'error');
      }
      // this.loading = false;
    },
    formatDate(time) {
      return moment(time)
        .tz(this.pagePreference.standardTimezone || 'America/Los_Angeles')
        .format('LLLL');
    },
  },
  watch: {
    attributes: {
      handler: function(val, oldVal) {
        this.showHeader = !utilities.comparisonTwoObject(this.defaultForm, this.attributes);
        // if (this.showHeader) {
        //   this.$v.$reset();
        // }
      },
      deep: true,
    },
  },

  validations: {
    attributes: {
      title: {
        required,
        minLength: minLength(0),
        maxLength: maxLength(255),
      },
    },
  },
  computed: {
    pagePreference() {
      return this.$store.getters.pagePreference;
    },
  },
};
</script>
<style lang="scss">
.customer-name {
  color: $main-color;
}
.v-input__icon--append {
  display: none;
}
.user-link {
  color: #a98a57;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
.automate {
  border-left: 3px solid #a98a57;
  background-color: #a98a571a;
}
.timeline {
  margin-left: -30px;
}
.timeline-link {
  overflow-wrap: break-word;
}
.order-page--detail {
  .btn-link {
    color: $main-color;
    &:hover {
      text-decoration: none;
    }
  }
  .edit-label {
    font-weight: 700;
    color: $main-color;
    cursor: pointer;
  }
  .image-item {
    width: 50px;
    height: 50px;
    border: 0.2px solid #dddd;
    margin-right: 10px;
  }
  .title-info-customer {
    font-weight: 700;
    line-height: 1rem;
    text-transform: uppercase;
    font-size: 12px;
  }
  .v-expansion-panel:before {
    box-shadow: none;
  }
  .v-expansion-panel-header__icon {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
</style>
